import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../../layout";
import Gobblet from "../../games/gobblet";
import config from "../../../data/siteconfig";

class CodePage extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 0 };
  }

  componentDidMount() {
    fetch(config.appScriptExecUrl + "?id=gobblet-play-counter&action=get", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => this.setState({ count: data["value"] }))
      .catch((err) => console.warn(err));
  }

  countCallback = () => {
    fetch(config.appScriptExecUrl + "?id=gobblet-play-counter&action=hit", {
      method: "GET",
    });
    // .then(res => res.json()).then(data => (
    // console.log(data)
    // )).catch(err => console.warn(err));
    this.setState({ count: this.state.count + 1 });
  };

  render() {
    // const projects = this.props.data.projects.frontmatter.content;
    // const webgames = this.props.data.webgames.frontmatter.content;
    return (
      <Layout title="code">
        <h1>code</h1>
        <p>
          See <a href="https://github.com/milotrince">GitHub</a> for latest
          information.
        </p>
        {/* <section className="section">
          <h2>projects</h2>
          {projects.map((p) => (
            <a href={p.link} className="media box">
              <div className="media-content">
                <p className="is-size-4 is-marginless"><strong>{p.name}</strong></p>
                <p className="is-marginless">{p.time}</p>
                <p>{p.text}</p>
              </div>
              <div className="media-right">
                <img src={p.icon} width="64"></img>
              </div>
            </a>
          ))}
        </section> */}
        <h3>play a game with me?</h3>
        <p>It's just like tic-tac-toe <img class="emote" src="/img/emotes/wink.gif"></img></p>
        <Gobblet mode={"ai"} winCallback={this.countCallback} />
        <p>played {this.state.count || "?"} times</p>
        {/* <Counter callback={this.countCallback} name="gobblet-game-count" /> */}
        {/* {webgames.map((p) => (
            <Link to={"/code/games/" + p.name} className="media box">
              <div className="media-content">
                <p className="is-size-4 is-marginless">{p.icon} <strong>{p.name}</strong></p>
                <p className="is-marginless">
                  {p.text}
                </p>
                <div className="has-text-right">
                  {p.tags.map((t) => (
                    <button>{t}</button>
                  ))}
                </div>
              </div>
            </Link>
          ))} */}
      </Layout>
    );
  }
}

export const query = graphql`
  query CodePageQuery {
    projects: markdownRemark(frontmatter: { for: { eq: "projects" } }) {
      frontmatter {
        content {
          name
          time
          link
          icon
          text
        }
      }
    }
    webgames: markdownRemark(frontmatter: { for: { eq: "webgames" } }) {
      frontmatter {
        content {
          name
          icon
          text
          tags
        }
      }
    }
  }
`;

export default CodePage;
